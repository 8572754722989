.MuiCardContent-root  {
        display: flex !important;
        flex-direction: column !important;
        align-items: flex-start !important;
     
}

.Card-Content {
    display: flex ;
    flex-direction: column ;
    align-items: flex-start;
}



.css-1ri6ub7-MuiPaper-root-MuiCard-root {
    max-width: 250px !important;
    height: 250px !important;
    margin-top: 1rem;
}

.css-w88nxk {
    max-width: 250px !important;
    height: 250px !important;
}
.css-r40f8v-MuiTypography-root {
    padding: 0rem;
}


.css-t1nuxs {
    font-size: 1rem !important;
}

