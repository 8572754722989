.nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f4f4f4;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    /* box-shadow: 0px 8px 16px 0px rgb(189, 188, 188);
     */
     box-shadow: 0 2px 6px rgb(186, 183, 183);
    position: fixed; 
    top: 0%;
    z-index: 9999;
    width: 100%; 
    height: 4rem;
}
.logo {
  display: flex;
  align-items: center;
  margin-left: 2rem;
  height: 3rem;
}
.horizontal-logo {
  width: 3rem;
}
.communities {
    
    padding: 16px;
    font-size: 16px;
    border: none;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
    direction:rtl;
    margin-left: 5rem;
    margin-right: 5rem;
   
  }
  
  .dropdown-content  {
    display: none;
    position: absolute;
    background-color: #f4f4f4;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgb(189, 188, 188);
    z-index: 1;
  
  }
  .dropdown-content-contact {
    display: none;
    position: absolute;
    background-color: #f4f4f4;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgb(189, 188, 188);
    z-index: 1;
    direction:ltr;
  padding: 2px;

  
  }
  
  .dropdown-content p {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content-contact p {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content p:hover {background-color: #c9bdb5;}
  
  .dropdown:hover .dropdown-content {display: block;}
  .dropdown:hover .dropdown-content-contact {display: flex;
  flex-direction: column;
justify-content: flex-start;}
  .dropdown-content-contact .email-paragraph {cursor: pointer;}
  

  .phone-icon, .email-icon, .calendar-clock-icon {
    width: 2rem;
    height: 3rem;
  }

  .nav-contact-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: .3rem;
  }

  label {
    margin: 0 3rem 0 0;
    font-size: 3rem;
    line-height: 6rem;
    display: none;
    width: 2rem;
    float: right;
  }

  #toggle {
    display: none;
  }

  .menu {
    display: flex;
    /* height: 4rem; */
  }

  @media only screen and (max-width:  1015px) {
    label {
      display: block;
      cursor: pointer;
    }
    .menu {
      text-align: center;
      width: 100%;
      display: none;

    }
    .menu .dropdown {
      clear: right;
      display: block;
      border-bottom: 0.1rem solid #884d3b;
      background-color: #f4f4f4;
      margin-left: 1rem;
      margin-right: 1rem;
      padding: 1rem;
      /* padding-top: 4rem; */
  
      
    }
    .dropdown p {
      margin: 0px;
    }
    #toggle:checked~.menu {
      display: block;
      /* margin: 1.5rem; */

      transition: all .5s ease;
      position: absolute;
      margin-top: 15rem;
      background-color: #f4f4f4;
      padding-bottom: 1rem;
    }
    .dropdown:hover .dropdown-content-contact  {
      /* position: relative; */
      display: block;
    align-items: center;
   
  
    }
    .dropdown:hover .dropdown-content  {
      /* position: relative; */
      display: block;
      margin-right: 30%;
    }
    .about-location-p {
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 1rem;
      margin-right: 1rem;
      padding: 1rem;
      border-bottom: 0.1rem solid #884d3b;
      background-color: #f4f4f4;
    }
    /* .dropdown {
      position: relative;
  display: inline-block;
    } */
  }