.about-location {
    background-color: #f4f4f4;
    padding: 1rem;
}

.location-education {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #884d3b;
}

.college {
    display: flex;
    align-items: center;
}

.college h4 {
    padding-right: 4rem;
}

.location-public-schools {
    border-top: 1px solid #884d3b;
    border-bottom: 1px solid #884d3b;
   
    margin: 1rem;
     padding: 1rem;   
}

.public-schools-container, .attractions-container, .get-to-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.about-location-top {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.about-location-top p {
    font-size: 18px;
    width: 30vw;
    padding: 5rem !important;
    border-radius: 4px;
    background: #884d3b;
    opacity: .8;
    box-shadow: 0px 8px 16px 0px rgb(186, 183, 183);
}


.about-location h2 {
    width: 30rem;
    font-size: 3rem;
}

.google-map {
    padding: 1rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.location-attractions, .location-get-to {
    border-bottom: 0.3rem solid #884d3b;
    margin: 1rem;
     padding: 1rem;  
}

.attraction, .school, .get-to {
    width: 10rem;
}

.university-location-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.near-by-attraction {
    text-align: left;
    margin-left: 4rem;
    margin-right: 4rem;
}

.near-by-attraction h4 {
    font-size: 30px;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 1rem;
}


@media only screen and (max-width:  779px) {
    .university-location-cards {
      justify-content: center;
    }
    .near-by-attraction {
        text-align: center;
    }
    .MuiPaper-root  {
        margin: 2rem;
    }
  }