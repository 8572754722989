.home-header {
    display: flex;
    justify-content: flex-start;
    height: 80vh;
    align-items: center;
}
h1 {
   
    padding: 2rem;
    width: 60%;
    text-shadow: 1px 1px 1px #000;
    font-size: 50px;
    margin-right: 3rem;
    color: white;
}

.homepage-content-container {
    background: #f4f4f4;
}
.homepage-middle, .homepage-bottom {
    display: flex;
    
    justify-content: space-between;
}

.homepage-middle  img {
    width: 50vw;
    height: 75vh;
    box-sizing: border-box;
    object-fit: cover;
}

.homepage-bottom img {
    box-sizing: border-box;
    max-width:100%; height:auto
}



.div-photo {
        width: 50vw;
    height: 75vh;
    box-sizing: border-box;
}


.homepage-contact {
    display: flex;
    justify-content: space-around;
    padding: 1rem;
   align-items: center;
    flex-direction: column;
    width: 50vw;
    height: 75vh;
    box-sizing: border-box;
}

.homepage-contact-info {
    width: 25vw;
    overflow-wrap: break-word;
    font-size: 3vh;
}

.about-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50vw;
    height: 75vh;
    font-size: 3vh;
    box-sizing: border-box;
}

.about-paragraph {
    width: 50%;
}

.address {
    cursor: pointer;
}

/* @media only screen and (max-width:  675px) {
    .home-header {

 justify-content: center;
    }
    .about-info, h2 {
        font-size: 1.17rem;
    }
    .homepage-bottom, .homepage-middle {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .homepage-middle {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }
    .about-info, .homepage-contact {
        height: 100% !important;
        width: 100%;
        padding: .4rem;
        padding: 3rem;
    }
    .homepage-contact {
        display: flex;
        flex-direction: row;

    }
    .div-photo {
        width: 100vw;
    }
    .homepage-contact-info, p {
        font-size: .5rem;
        padding-right: 0rem !important;
    }
    .about-paragraph {
        padding-right: 0rem !important;
        font-size: .5rem;
    width: 90vw;
    }

} */
@media only screen and (max-width:  940px) { 
    .home-header {

        justify-content: center;
           }
           .about-info, h2 {
               font-size: 1.17rem;
           }
           .homepage-bottom, .homepage-middle {
               display: flex;
               flex-direction: column;
               align-items: center;
           }
           .homepage-middle {
               display: flex;
               flex-direction: column-reverse;
               align-items: center;
           }
           .about-info, .homepage-contact {
               height: 100% !important;
               width: 100%;
               padding: .4rem;
               padding: 3rem;
           }
           .homepage-contact {
               display: flex;
               flex-direction: row;
               flex-wrap: wrap;
       
           }
           .div-photo {
               width: 100vw;
           }
           .homepage-contact-info, p {
               font-size: 1rem;
               padding-right: 0rem !important;
           }
           .about-paragraph {
               padding-right: 0rem !important;
               font-size: 1rem;
           width: 90vw;
           }
}
