
footer {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #cb957c;
    flex-wrap: wrap;
}

.footer-info {
    width: 50vw;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
}

.footer-logo {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    align-items: center;
}

.footer-img {
    height: 20vh;
    width: 15vh;
}

footer p {
    font-weight: 300;
}

@media only screen and (max-width:  555px) {
    .footer-logo {

      margin-left: 0px;
    }
}
@media only screen and (max-width:  770px) {
    footer {
     justify-content: center;
    }
  }