

.complexes {
    background:#f4f4f4 ;
    padding: 1rem;
}

.complex-info {
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: space-around;
 
    /* flex-wrap: wrap; */
}
.error-div {
    padding: 8rem;
    display: flex;
    justify-content: center;
    
}

.error-div h2 {
    width: 30vw;
    padding: 5rem;
    border-radius: 4px;
    background: #884d3b

}

.complex-info h3 {
    color: white;
    padding-bottom: 1rem;
}
.heading-div {
    height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: white;
    margin-left: 2rem;
    margin-right: 2rem;
    box-shadow: 0px 8px 16px 0px rgb(186, 183, 183);
}

.heading-div h2 {
    font-size: 35px;
}
.apartment-wrapper {
    background: #884d3b;
    opacity: .8;
    box-shadow: 0px 8px 16px 0px rgb(186, 183, 183);
    border-radius: 4px;
    padding: 4rem;
    width: 75vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    block-size: fit-content;
    box-sizing: border-box;
}

.css-1q04urf {
    margin: 1rem !important;
}
.apartment-wrapper h3 {
    font-size: 30px;

}
.apartments {
   display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.apartment-image {
    height: 20vh;
    border-radius: .5rem;
}
.css-1oqv2c0-MuiPaper-root-MuiCard-root {
    display: flex;
      width: fit-content;
    align-items: center;
    height: 20vh;
    margin: 1rem;
    overflow: hidden;
    height: fit-content
}

.apartment-preview-picture {
    width: 100px !important;
    height: 120px;
    object-fit: scale-down !important;
}

.ind-complex-apartment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 3rem;
    width: 35vw;
    border-radius: .5rem;
    padding: 1rem;
    height: 25vh;
    background-color: #f4f4f4;
    box-shadow: 0px 4px 4px 0px rgb(186, 183, 183);
    flex-wrap: wrap;
}

@media only screen and (max-width:  767px) {
    .ind-complex-apartment {
        display: flex;
        width: 100%;
        height: 100%;
        flex-wrap: nowrap;
    }
   

}

.complex-overview {
    font-size: 18px;
    width: 30vw;
    margin-right: 1rem;
    color: white;
    padding: 5rem;
    border-radius: 4px;
    background: #884d3b;
    opacity: .8;
    box-shadow: 0px 8px 16px 0px rgb(186, 183, 183);
    margin-bottom: 1rem;
}

.complex-overview, .apartment-features {
    width: 40vw;
}


.complex-apartments-wrapper {
    display: flex;
    justify-content: center;
    padding: 2rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;

}

.complex-apartments {
    background-color: #cb957c ;
    box-shadow: 0px 8px 16px 0px rgb(186, 183, 183);
    padding: 2rem;
}
.complex-apartment-div {
    height: 30rem;
    width: 25rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    transition: all .5s ease;
    margin-left: 5rem;
    margin-right: 5rem;
    margin-bottom: 1rem;
}
.swiper-paragraph {
    /* background: white;
    opacity: .8; */
    color: black;
}

.complex-apartment-div:hover {
    transform: scale(1.1)
}

.slick-slider {
    width: 50vw;
}

.swiper-slide {
    display: flex;
    justify-content: center;
  }

  .swiper-button-next:before, .swiper-button-next:after  {
    color: #884d3b;
 
    /* width: 5vw; */

  }
  .swiper-button-prev:after,  .swiper-button-prev:before {
 color: #884d3b;
    /* width: 5vw; */
   
  }


  .swiper-scrollbar-drag {
    background: #884d3b !important;
  }

  /* @media only screen and (max-width:  555px) {
    .css-1oqv2c0-MuiPaper-root-MuiCard-root {
        /* height: fit-content;
         */
         /* height: 30vh;
    } */
/* } */

@media only screen and (max-width:  770px) {
  .complex-info {
    flex-wrap: wrap;
  }
  .complex-overview {
    margin-right: 0rem !important;
}
}