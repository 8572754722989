.ind-apartment {
    margin: 9rem;
}

.ind-apartment-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 30vw;
    padding: 2rem;
    opacity: .8;
}

.ind-apartment-info h2 {
    font-size: 30px;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 8px;
}

.apartment-details {
    display: flex;
}

.apartment-details h4 {
    border-right: solid 1px black;
    margin-right: 0.5rem;
    padding-right: 0.5rem;
}
.background-image {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

 .rent-price {
     background-color: #f4f4f4;
     display: flex;
     justify-content: space-between;
     width: 100%;
     flex-wrap: wrap;
}

.header-rent-price {
    margin-top: 0rem;
    background: #cb957c;
    box-shadow: 0px 8px 16px 0px rgb(186 183 183);
    height: 2rem;
    margin-left: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem;
    opacity: .8;
    width: 30vw;
    margin-bottom: 0;
}
.ind-apartment-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

button {
    padding: 1rem;
    background: lightblue;
    border: none;
    margin: 0.5rem;
    width: 25vw;
}

button:hover {
    cursor: pointer;
}

.ind-apartment-top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

}

.navigation {
    display: flex;
    margin-top: 1;
    align-items: center;
    justify-content: flex-start;
    margin-left: 9vw;
    margin-top: 10rem;
}
/* .breadcrumbs {
    padding-bottom: 1rem;
}

.MuiBreadcrumbs-separator {
    color:#884d3b;
    font-size: 25px;
    font-weight: 700;
    margin: 6px 4px 0;
    line-height: 10px;
} */

/* .breadcrumbs a {
    text-decoration: none;
    color: black;
}
.breadcrumbs p {
    font-size: 12px;
    padding: 0 !important;
} */

.background-image {
    width: 100%;
    padding-top: 8%;
}

.ind-apartment-bottom {
  border-top: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.ind-apartment-bottom h2 {
    font-size: 30px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 8px;
}

.ind-apartment-bottom, p {
    padding-right: 1rem;
    align-items: center;
}
ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
li {
  padding: 0.4rem;
  padding-inline-end: 4ch;
}
.apartment-layout {
    width: 30vw;
    margin: 2rem;
    background-color: #f4f4f4;
}

.amentities-paragraphs {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}



  .apartment-image-swiper {
    width: 100%;
    height: 70vh;;
  }
  
  .swiper-container {
          display: flex;
          justify-content: center;
          flex-direction: column;
          /* border-bottom: 1px solid black; */
          padding-bottom: 3rem;

  }
  .modal-swiper-div {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      cursor: pointer;
      margin-bottom: 1rem;
  }
  
  .swiper-slide {
      display: flex;
      justify-content: center;
    }

  .swiper-wrapper {
     width: 45vw;
  }

    .swiper-button-next:before, .swiper-button-next:after  {
      color: #884d3b;
      background: white;
      padding: 1rem;
      border-radius: 4rem;
      margin-right: 3rem;
      width: 2vw;
      height: 2vh;

  
    }
    .swiper-button-prev:after,  .swiper-button-prev:before {
      color: #884d3b;
      background: white;
      padding: 1rem;
      border-radius: 4rem;
      margin-left: 3rem;
     
    }


    .swiper-pagination {
      --swiper-pagination-bullet-vertical-gap: 10rem !important;
      --swiper-pagination-bullet-margin-top: 8px !important;
      padding-bottom: 1rem;
    }
  
    .swiper-pagination-bullet-active {
      background-color: #000 !important;
    }
  
  
  .swiper-images {
    width: 40rem;
  }
  :root {
    --swiper-theme-color: none !important;
  }

  .back-click {
    display: flex;
    align-items: center;
    width: 25vw;
    background-color: lightblue;
   padding: 0rem  !important;
   justify-content: center;
  }

  .back-arrow {
    width: 1rem;
    height: 1rem;
    padding: .5rem
  }
  
  @media only screen and (max-width:  1438px) {
    .ind-apartment-info {
      align-items: center;
      /* border-bottom: 1px solid black; */
      width: 41vw;

    }

    .ind-apartment {
      display: flex;
    flex-direction: column;
    align-items: center;
    }

    .ind-apartment-bottom {
      align-items: center;
    }
    .ind-apartment-top {
      width: 100%;
      align-items: center;
    }
    .swiper-container {
      width: 100%;
      align-items: center;
      border-bottom: 1px solid black;
 
    }
  }

  @media only screen and (max-width:  655px) { 
    .apartment-image-swiper {
      width: 45vw;
      height: 70vh;;
    }
    .swiper-slide {
      width: 45vw !important;
    }
    .swiper-images {
      width: 45vw;
    }
  }
