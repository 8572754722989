@import url("https://fonts.googleapis.com/css?family=Josefin Slab");

* {
  /* font-family: "PT Sans", sans-serif; */
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
}

html {
  background: #f4f4f4 ;
  
}


.App {
  text-align: center;

}
.App p {
  font-weight: 300;
}
.main {

  margin-top: 5rem;
}

/* beige #efe1d8
dark red #552224
orange #db8342
dark beige/orange #cb957c
dark purple #93706d
light purple #ab8c94
brown #8a5d50
purple brown #724c49
red brown #884d3b */
